<script setup>
  const BG = '#fcfbfa';
  const FG = '#3c00ff';

  console.log(
    '%c♥ pinkyellow.com ♥',
    `
    font-size: 16px;

    background-color: ${BG};
    color: ${FG};

    border: 2px solid ${FG};

    margin: 16px 0;
    padding: 16px 20px;
    `,
  );
</script>

<!----------------------------------------------------------------------------->

<template>
  <Html made-by="pinkyellow.com 🌷🌻" />
</template>
