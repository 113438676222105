import { flare } from '@flareapp/js';
import { flareVue } from '@flareapp/vue';

export default defineNuxtPlugin((nuxtApp) => {
  const {
    public: { flareKey },
  } = useRuntimeConfig();

  flare.light(flareKey);
  flare.beforeSubmit = (report) => {
    return {
      ...report,
      application_path: report.context.request?.url,
    };
  };

  nuxtApp.vueApp.use(flareVue);
});
