import VueFathom from '@ubclaunchpad/vue-fathom';

export default defineNuxtPlugin(({$config, vueApp}) => {
  if ($config.public.appEnv === 'production') {
    vueApp.use(VueFathom, {
      siteID: $config.public.fathomSiteId,
      settings: {
        spa: 'history',
      },
    });
  }
});
