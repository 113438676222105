import Telescope from '~/pinkyellow/telescope';

// --

export default (ref, config) => {
  onMounted(() => {
    Telescope.detect(ref.value, config);
  });

  onBeforeUnmount(() => {
    ref.value && Telescope.ignore(ref.value);
  });
};
