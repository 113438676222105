export default function Sweet16(sixteen = 16.0, max = Infinity) {
  // ---------------------------------------------------------------------------

  const party = document.documentElement.style;

  function celebrate() {
    party.fontSize = `${Math.min(Math.max(sixteen, 0.01 * window.innerWidth), max)}px`;
  }

  addEventListener('resize', celebrate);
  addEventListener('orientationchange', celebrate);

  celebrate();
}
